<template>
	<div class="open-account-bank">
		<div class="step-box">
			<div class="step-item">
				<van-image width="20" :src="resourceURL + 'images/step01_h.png'"/>
				<p>填写资料</p>
			</div>
		
			<div class="step-item">
				<img :src="resourceURL + 'images/step_line_h.png'" alt="">
			</div>
		
			<div class="step-item">
				<van-image width="20" :src="resourceURL + 'images/step02_h.png'"/>
				<p>身份验证</p>
			</div>
			<div class="step-item">
				<img :src="resourceURL + 'images/step_line.png'" alt="">
			</div>
			<div class="step-item">
				<van-image width="20" :src="resourceURL + 'images/step03.png'"/>
				<p>提交审核</p>
			</div>
		</div>
		
		<van-form @submit="nextStep()" :label-width="70">

			<h4 class="h4_title">USDT账户信息</h4>

			<van-field
				readonly
				clickable
				label="主网类型"
				:value="indexType > -1 ? types[indexType].text : ''"
				placeholder="请选择主网类型"
				@click="showType = true"
				:rules="rules.type"
				required
			/>
			<van-popup v-model="showType" round position="bottom">
				<van-picker
					title="主网类型"
					show-toolbar
					:columns="types"
					:default-index="indexType"
					@cancel="showType = false"
					@confirm="onConfirmType"
				/>
			</van-popup>
			
			<van-field 
				v-model="formItem.address" 
				label="地址" 
				placeholder="请输入"
				required
				:rules="[{ required: true, message: '请输入地址' }]"
				/>
			
			<div class="common_block">
				<van-button type="warning" block native-type="submit">下一步</van-button>
			</div>
		</van-form>

		
	
	</div>
</template>

<script>
	export default {
		name: 'account_usdt',
		data() {
			return {
				formItem: {
					address: "",
					type: "1",
				},

				rules: {
					type: [
						{
							required: true,
							message: '请选择主网类型',
							trigger: 'onBlur'
						}
					],
					address: [
						{
							required: true,
							message: '请填写地址',
							trigger: 'onBlur'
						}
					]
				},

				token: "",
				
				indexType: 0,
				showType: false,

				resourceURL: this.$axios.defaults.resourceURL,
								
				disabledSubmit: true,
				
				types: [{type: 1, text: 'ERC20'}, {type: 2, text: 'OMNI'}, {type: 3, text: 'TRC20'}],			
			}
		},
		mounted() {
			
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.token = this.$route.query.token
								
				this.init()
			}
		},
		methods: {
			init(){
				this.$axios({
					method: 'post',
					url: 'account_open/info',
					data: {
						token: this.token
					}
				}).then ((res) => {
					if (res.success) {

						if (res.data.status == 2) {
							//	待审核
							this.$router.replace('/account/success')
						} else if (res.data.status == 3) {
							//	不通过
							this.$router.replace('/account/fail?token=' + this.token)
						} else {
							
							var usdt_data = res.data.usdt_data

							if (usdt_data.length != 0) {
								this.formItem.address = usdt_data.address ? usdt_data.address : ""
								
								this.formItem.type = usdt_data.type ? usdt_data.type : ""

								this.types.forEach((item, key) => {
									if(item.type == usdt_data.type){
										this.indexType = key
									}								
								})
							}

						}

					}else{
						this.$toast.fail(res.error_text)
					}
				})

				
			},

			onConfirmType(value, index) {
				if (value) {
					this.indexType = index

					this.formItem.type = value.type
				}
				
				this.showType = false
			},
			nextStep(){
				
				this.$axios({
					method: 'post',
					url: 'account_open/alter',
					data: {
						token: this.token,
						usdt_data: JSON.stringify(this.formItem)
					}
				}).then ((res) => {
					if (res.success) {
						if (res.data.hold_lock == 2) {
							this.$router.push('/account/success')
						} else {
							this.$router.replace('/account/lock?token=' + this.token)
						}
					}else{
						this.$toast.fail(res.error_text)
					}
				})
				
			}
		},
	}
</script>